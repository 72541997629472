import React from 'react'
import { TASKS } from '../../dummy'
import styles from './Tasks.module.scss'

const Task = ({ title, desc, foolDesk, url, openModalWithData }) => {
	const handleModal = () => {
		const content = (
			<div className={styles.wrap}>
				{url && (
					<div className={styles.imgWrap}>
						<img src={url} alt='' />{' '}
					</div>
				)}
				{foolDesk && (
					<div className={styles.deskWrap}>
						<p>{foolDesk}</p>
					</div>
				)}
			</div>
		)
		openModalWithData(title, React.cloneElement(content))
	}

	return (
		<div className='taskBlock' onClick={handleModal}>
			<div className='taskTitle'>{title}</div>
			<div className='dummyDesc'>{desc}</div>
		</div>
	)
}

const Tasks = ({ openModalWithData }) => {
	return (
		<div className='tasks'>
			{TASKS.map((task, index) => (
				<Task
					key={index}
					title={task.title}
					desc={task.desc}
					foolDesk={task.foolDesk}
					url={task.url}
					openModalWithData={openModalWithData}
				/>
			))}
		</div>
	)
}

export default Tasks

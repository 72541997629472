export const TASKS = [
	{
		title: 'экономия',
		desc: 'благодаря использованию нейросетей мы можем значительно ускорить процесс работы над проектом, экономя ваше время и ресурсы',
		foolDesk:
			'Благодаря применению передовых технологий на основе нейронных сетей, мы можем существенно оптимизировать и ускорить процесс работы над вашим проектом. Использование этих интеллектуальных алгоритмов позволяет нам автоматизировать ряд рутинных задач, высвобождая ценное время Ваших специалистов.\n' +
			'\n' +
			'Нейросетевые модели способны быстро анализировать большие объемы данных, выявлять закономерности и предлагать эффективные решения. Это дает нам возможность значительно сократить временные и ресурсные затраты на разработку, тестирование и итерации.\n' +
			'\n' +
			'В результате, Вы получаете качественный продукт в более сжатые сроки, что положительно сказывается на общей производительности и рентабельности Вашего бизнеса. Мы гордимся тем, что можем предложить Вам передовые технологии, которые помогут Вам достичь Ваших целей максимально эффективно.',
		url: 'https://sun6-22.userapi.com/impg/j6df09gYfVzQKOmJBNCEmg9py0TPw8SfvXTwKw/nN_-OZ3utwU.jpg?size=500x0&quality=95&sign=736b07410f9a1f3be723c73f75545751',
	},
	{
		title: 'философия',
		desc: 'благодаря использованию нейросетей мы можем значительно ускорить процесс работы над проектом, экономя ваше время и ресурсы',
		foolDesk:
			'Благодаря применению передовых технологий на основе нейронных сетей, мы можем существенно оптимизировать и ускорить процесс работы над вашим проектом. Использование этих интеллектуальных алгоритмов позволяет нам автоматизировать ряд рутинных задач, высвобождая ценное время Ваших специалистов.\n' +
			'\n' +
			'Нейросетевые модели способны быстро анализировать большие объемы данных, выявлять закономерности и предлагать эффективные решения. Это дает нам возможность значительно сократить временные и ресурсные затраты на разработку, тестирование и итерации.\n' +
			'\n' +
			'В результате, Вы получаете качественный продукт в более сжатые сроки, что положительно сказывается на общей производительности и рентабельности Вашего бизнеса. Мы гордимся тем, что можем предложить Вам передовые технологии, которые помогут Вам достичь Ваших целей максимально эффективно.',
		url: 'https://sun6-22.userapi.com/impg/j6df09gYfVzQKOmJBNCEmg9py0TPw8SfvXTwKw/nN_-OZ3utwU.jpg?size=500x0&quality=95&sign=736b07410f9a1f3be723c73f75545751',
	},
	{
		title: 'престиж',
		desc: 'благодаря использованию нейросетей мы можем значительно ускорить процесс работы над проектом, экономя ваше время и ресурсы',
		foolDesk:
			'Благодаря применению передовых технологий на основе нейронных сетей, мы можем существенно оптимизировать и ускорить процесс работы над вашим проектом. Использование этих интеллектуальных алгоритмов позволяет нам автоматизировать ряд рутинных задач, высвобождая ценное время Ваших специалистов.\n' +
			'\n' +
			'Нейросетевые модели способны быстро анализировать большие объемы данных, выявлять закономерности и предлагать эффективные решения. Это дает нам возможность значительно сократить временные и ресурсные затраты на разработку, тестирование и итерации.\n' +
			'\n' +
			'В результате, Вы получаете качественный продукт в более сжатые сроки, что положительно сказывается на общей производительности и рентабельности Вашего бизнеса. Мы гордимся тем, что можем предложить Вам передовые технологии, которые помогут Вам достичь Ваших целей максимально эффективно.',
		url: 'https://sun6-22.userapi.com/impg/j6df09gYfVzQKOmJBNCEmg9py0TPw8SfvXTwKw/nN_-OZ3utwU.jpg?size=500x0&quality=95&sign=736b07410f9a1f3be723c73f75545751',
	},
	{
		title: 'качество',
		desc: 'благодаря использованию нейросетей мы можем значительно ускорить процесс работы над проектом, экономя ваше время и ресурсы',
		foolDesk:
			'Благодаря применению передовых технологий на основе нейронных сетей, мы можем существенно оптимизировать и ускорить процесс работы над вашим проектом. Использование этих интеллектуальных алгоритмов позволяет нам автоматизировать ряд рутинных задач, высвобождая ценное время Ваших специалистов.\n' +
			'\n' +
			'Нейросетевые модели способны быстро анализировать большие объемы данных, выявлять закономерности и предлагать эффективные решения. Это дает нам возможность значительно сократить временные и ресурсные затраты на разработку, тестирование и итерации.\n' +
			'\n' +
			'В результате, Вы получаете качественный продукт в более сжатые сроки, что положительно сказывается на общей производительности и рентабельности Вашего бизнеса. Мы гордимся тем, что можем предложить Вам передовые технологии, которые помогут Вам достичь Ваших целей максимально эффективно.',
		url: 'https://sun6-22.userapi.com/impg/j6df09gYfVzQKOmJBNCEmg9py0TPw8SfvXTwKw/nN_-OZ3utwU.jpg?size=500x0&quality=95&sign=736b07410f9a1f3be723c73f75545751',
	},
]

export const Tags = [
	{
		id: 1,
		name: 'Визуализации интерьера',
	},
	{
		id: 2,
		name: 'Визуализации домов',
	},
	{
		id: 2,
		name: 'Визуализации новостроек',
	},
]

import styled from 'styled-components'

export const FooterContainer = styled.footer`
	height: 100px;
	background-color: #262626;
	color: #fff;
	padding: 40px 20px;

	@media (max-width: 768px) {
		height: fit-content;
	}
`

export const FooterContent = styled.div`
	width: 100%;
	height: 100%;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px;

	@media (max-width: 768px) {
		flex-direction: column;
	}
`

export const ContactInfo = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	gap: 10px;
	width: 100%;

	a {
		color: #fff;
		text-decoration: none;
		transition: color 0.3s ease;

		&:hover {
			color: #f56512;
		}
	}

	@media (max-width: 768px) {
		justify-content: center;
		align-items: center;
	}
`

export const Socials = styled.div`
	display: flex;
	flex: 1;
	gap: 10px;
	width: 100%;

	svg {
		width: 40px;
		height: auto;
		fill: #fff;
		text-decoration: none;
		transition: color 0.3s ease;

		&:hover {
			fill: #f56512;
		}
		cursor: pointer;
	}

	@media (max-width: 768px) {
		justify-content: center;
		align-items: center;
	}
`

export const LogoWrapper = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
	gap: 10px;
`

export const Copyright = styled.div`
	height: 100%;
	flex: 1;

	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	font-size: 14px;
	color: #999;
	text-align: right;

	a {
		color: #f56512;
		text-decoration: none;
		transition: color 0.3s ease;

		&:hover {
			color: #fff;
		}
	}

	@media (max-width: 768px) {
		text-align: center;
	}
`
export const Image = styled.img`
	width: ${props => props.size || '40px'};
	height: ${props => props.size || '40px'};
	color: #f56512;

	path {
		fill: white;
	}
`

import { AtSign, Phone } from 'lucide-react'
import React from 'react'
import {
	ContactInfo,
	Copyright,
	FooterContainer,
	FooterContent,
	LogoWrapper,
	Socials,
} from './styled'
import { ReactComponent as Telegram } from './telegram.svg'
import { ReactComponent as Vk } from './vk.svg'

const Footer = () => {
	const currentYear = new Date().getFullYear()

	return (
		<FooterContainer>
			<FooterContent>
				<ContactInfo>
					<a
						href='mailto:info@artell.studio'
						style={{ display: 'flex', alignItems: 'center' }}
					>
						<AtSign style={{ marginRight: '0.5rem' }} /> info@artell.studio
					</a>
					<a
						href='tel:+7 (495) 123-45-67'
						style={{ display: 'flex', alignItems: 'center' }}
					>
						<Phone style={{ marginRight: '0.5rem' }} />
						+7 (495) 123-45-67
					</a>
					<Socials>
						<a>
							<Vk fill='#fff' />
						</a>
						<a>
							<Telegram fill='#fff' />
						</a>
					</Socials>
					{/* <a href='#'>г. Москва, ул. Пушкина, д. 10</a> */}
				</ContactInfo>
				<LogoWrapper>
					<img src='images/footer_logo.webp' alt='logo' />
				</LogoWrapper>
				<Copyright>
					<a href='politConf.pdf' target='_blank' rel='noopener noreferrer'>
						Пользовательское соглашение
					</a>
					ArtTell.Studio &copy; {currentYear}. Все права защищены.
				</Copyright>
			</FooterContent>
		</FooterContainer>
	)
}

export default Footer

import React, { useEffect, useState } from 'react'
import { NavMenu } from '..'

const Header = ({ openModalWithData }) => {
	const [scrollPosition, setScrollPosition] = useState(0)

	useEffect(() => {
		const handleScroll = () => {
			setScrollPosition(window.pageYOffset)
		}

		window.addEventListener('scroll', handleScroll)

		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [])

	return (
		<div className={`header ${scrollPosition > 0 ? 'header--colored' : ''}`}>
			<img className='logo' src='images/art.png' alt='' />
			<NavMenu openModalWithData={openModalWithData} />
		</div>
	)
}

export default Header

import classNames from 'classnames'
import { StepBack, StepForward } from 'lucide-react'
import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import styles from './Demo.module.scss'
import { PoligonSvg } from './images'

const generateAssetPaths = id => ({
	forward: `Demo/${id}.mp4`,
	backward: `Demo/${id}_revers.mp4`,
	image: `Demo/${id}.webp`,
	svg: `Demo/svg/${id}.svg`, // Если SVG недоступен, можно динамически исключить его позже.
})

const videoAssets = [
	generateAssetPaths(1),
	generateAssetPaths(2),
	generateAssetPaths(3),
	generateAssetPaths(4),
]

const Demo = ({ openModalWithData }) => {
	const [currentIndex, setCurrentIndex] = useState(0) // Индекс текущего ролика
	const [previousIndex, setPreviousIndex] = useState(0)
	const [showImage, setShowImage] = useState(true) // Отображение картинки во время смены
	const [playing, setPlaying] = useState(false) // Состояние воспроизведения
	const [direction, setDirection] = useState('forward')
	const [isFirstPlay, setIsFirstPlay] = useState(true)

	const handleVideoEnd = () => {
		setPreviousIndex(currentIndex)
		setShowImage(true) // Показываем картинку после окончания видео
		setPlaying(false) // Останавливаем воспроизведение
	}

	// Показываем картинку при смене видео
	const handlePrev = () => {
		const prevIndex =
			(currentIndex - 1 + videoAssets.length) % videoAssets.length
		setPreviousIndex(currentIndex)
		setDirection('backward')
		setCurrentIndex(prevIndex)
	}

	// Обработка нажатия на Next — меняем видео и запускаем воспроизведение
	const handleNext = () => {
		const nextIndex = (currentIndex + 1) % videoAssets.length
		setPreviousIndex(currentIndex)
		setDirection('forward')
		setCurrentIndex(nextIndex)
	}

	const handleVideoReady = () => {
		if (!isFirstPlay) {
			setShowImage(false)
			setPlaying(true)
		}

		setIsFirstPlay(false)
	}

	// useEffect(() => {
	// 	if (isFirstPlay) {
	// 		setShowImage(true)
	// 	}
	// }, [isFirstPlay])

	return (
		<div className={styles['video-wrapper']}>
			{/* Видео всегда на экране */}
			<ReactPlayer
				url={
					direction === 'forward'
						? videoAssets[currentIndex].forward
						: videoAssets[currentIndex].backward
				}
				playing={playing}
				controls={false}
				width='100%'
				height='100%'
				onEnded={handleVideoEnd}
				onReady={handleVideoReady}
				config={{
					file: {
						attributes: {
							poster: videoAssets[currentIndex].image,
							preload: 'auto',
						},
					},
				}}
			/>

			<img
				src={videoAssets[previousIndex].image}
				alt='Final frame'
				className={classNames(
					styles['final-image'],
					showImage ? styles.visible : styles.hidden
				)}
			/>

			<div
				className={classNames(
					styles.poligonContainer,
					showImage ? styles.visible : styles.hidden
				)}
			>
				<PoligonSvg
					openModalWithData={openModalWithData}
					svgItem={videoAssets[currentIndex].svg}
				/>
			</div>

			<div className={styles.controls}>
				<button
					onClick={handlePrev}
					className={classNames(styles.btn, styles.back)}
				>
					<StepBack />
				</button>
				<button
					onClick={handleNext}
					className={classNames(styles.btn, styles.forward)}
				>
					<StepForward />
				</button>
			</div>
		</div>
	)
}

export default Demo

import React from 'react'
import CallForm from '../CallForm/CallForm'
const NavMenu = ({ openModalWithData }) => {
	const handleModal = (title, content) => event => {
		event.preventDefault()
		const content = <CallForm onClose={() => openModalWithData(null, null)} />
		openModalWithData(title, React.cloneElement(content))
	}
	return (
		<div className='navMenu'>
			<a className='mail' href='mailto:arttelstudio@inbox.com'>
				arttelstudio@inbox.com
			</a>
			<a className='tel' href='#' onClick={handleModal('Заказать звонок')}>
				заказать звонок
			</a>
			<a className='formLink' href='#'>
				онлайн заявка
			</a>
		</div>
	)
}

export default NavMenu

import React, { useEffect, useRef, useState } from 'react'

export const PoligonSvg = ({ openModalWithData, svgItem }) => {
	const [image, setImage] = useState(false)
	const [svgContent, setSvgContent] = useState(null)
	const svgContainerRef = useRef(null)

	const handleClick = title => {
		setImage(!image)
		const content = (
			<div>
				<img src='Demo/plane.jpg' alt='plane' />
			</div>
		)
		openModalWithData(title, React.cloneElement(content))
	}

	useEffect(() => {
		const loadSvg = async () => {
			try {
				const response = await fetch(svgItem)
				const text = await response.text()
				setSvgContent(text)
			} catch (error) {
				console.error('Error loading SVG:', error)
			}
		}

		loadSvg()
	}, [svgItem])

	useEffect(() => {
		if (svgContent && svgContainerRef.current) {
			// Теперь можно добавлять обработчики событий к элементам внутри SVG
			const svgElements = svgContainerRef.current.querySelectorAll('svg *') // Выбираем все элементы внутри SVG

			svgElements.forEach(element => {
				element.addEventListener('click', handleSvgElementClick)
			})

			// Очистка обработчиков при удалении компонента
			return () => {
				svgElements.forEach(element => {
					element.removeEventListener('click', handleSvgElementClick)
				})
			}
		}
	}, [svgContent])

	const handleSvgElementClick = event => {
		const clickedElement = event.target
		const content = (
			<div>
				<img src='Demo/plane.jpg' alt='plane' />
			</div>
		)
		openModalWithData(clickedElement.id, React.cloneElement(content))
	}

	if (!svgContent) {
		return <div>Loading...</div>
	}

	return (
		<div
			ref={svgContainerRef}
			style={{ display: 'contents' }}
			dangerouslySetInnerHTML={{ __html: svgContent }}
		/>
	)
}

import { send } from 'emailjs-com'
import { Formik } from 'formik'
import React, { useState } from 'react'
import InputMask from 'react-input-mask'
import * as Yup from 'yup'
import { Button } from '../../components'
import {
	CheckmarkIcon,
	CorrectMessage,
	CrossIcon,
	ErrorMessage,
	FileIcon,
	FileLabel,
	FilterCheckbox,
	FormHeader,
	InputsWrapper,
	InputWrapper,
} from './styled'

const validationSchema = Yup.object().shape({
	name: Yup.string()
		.required('Введите имя')
		.matches(/^[а-яА-ЯёЁ\s]+$/, 'Имя должно содержать только русские буквы'),

	phone: Yup.string()
		.required('Введите телефон')
		.matches(/^\+7\s\(\d{3}\)\s\d{3}-\d{2}-\d{2}$/, 'Неверный формат телефона'),

	email: Yup.string()
		.required('Введите адрес электронной почты')
		.email('Неверный адрес почты'),

	consent: Yup.boolean().oneOf(
		[true],
		'Необходимо согласие на обработку персональных данных'
	),
	file: Yup.mixed(),
})

const Form = () => {
	const [fileLoaded, setFileLoaded] = useState(false)

	const onSubmit = (values, { setSubmitting }) => {
		const toSend = {
			name: values.name,
			phone: values.phone,
			email: values.email,
			message: values.message,
			file: values.file ? values.file.name : '', // передаем имя файла, если он загружен
		}

		send('service_o32fxro', 'template_c0lxiuj', toSend, 'uRUT4bm_siS8iuQbU')
			.then(response => {
				console.log('SUCCESS!', response.status, response.text)
			})
			.catch(err => {
				console.log('FAILED...', err)
			})
			.finally(() => {
				setSubmitting(false)
			})
	}
	// onSubmit когда будет сервер

	// const onSubmit = (values, { setSubmitting }) => {
	// 	const formData = new FormData();

	// 	formData.append('name', values.name);
	// 	formData.append('phone', values.phone);
	// 	formData.append('email', values.email);
	// 	formData.append('message', values.message);

	// 	if (values.file) {
	// 		formData.append('file', values.file);
	// 	}

	// 	fetch('https://serverURL/api/submit', {
	// 		method: 'POST',
	// 		body: formData,
	// 	})
	// 		.then(response => {
	// 			if (response.ok) {
	// 				return response.json();
	// 			}
	// 			throw new Error('Ошибка при отправке формы');
	// 		})
	// 		.then(data => {
	// 			console.log('SUCCESS!', data);
	// 		})
	// 		.catch(err => {
	// 			console.log('FAILED...', err);
	// 		})
	// 		.finally(() => {
	// 			setSubmitting(false);
	// 		});
	// };

	return (
		<div className='form'>
			<FormHeader>
				<p>
					ЕСЛИ ВАС ИНТЕРЕСУЕТ 3D ВИЗУАЛИЗАЦИЯ, ПРОСТО ПРЕДОСТАВЬТЕ НАМ ПЛАН
					ПОМЕЩЕНИЯ И ПРИМЕРЫ ОЖИДАЕМОГО РЕЗУЛЬТАТА.
				</p>
				<p className='bold'>
					В ДАЛЬНЕЙШЕМ МЫ ОБСУДИМ ВСЕ ДЕТАЛИ ПРОЕКТА, ЧТОБЫ ТОЧНО ПОНЯТЬ ВАШИ
					ПОТРЕБНОСТИ И ЖЕЛАНИЯ.
				</p>
			</FormHeader>

			<Formik
				initialValues={{
					name: '',
					phone: '',
					email: '',
					message: '',
					file: null,
					consent: false,
				}}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					setFieldValue,
					isSubmitting,
					/* and other goodies */
				}) => (
					<form className='formBlock' onSubmit={handleSubmit}>
						<FormHeader>
							<h3>Свяжитесь с нами</h3>
							<p>
								Для того чтобы заказать смету, оформите заявку обратной связи.
								Если у Вас остались вопросы по стоимости и срокам, напишите на
								почту: info@artell.studio
							</p>
						</FormHeader>

						<InputsWrapper>
							<InputWrapper>
								{errors.name && touched.name ? (
									<ErrorMessage>
										<CrossIcon>&#10006;</CrossIcon>
										{errors.name}
									</ErrorMessage>
								) : values.name && touched.name ? (
									<CorrectMessage>
										<CheckmarkIcon>&#10003;</CheckmarkIcon>
										Имя введено верно
									</CorrectMessage>
								) : null}

								<input
									className='valueForm'
									type='text'
									name='name'
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.name}
									style={{
										borderColor: errors.name
											? 'red'
											: values.name && touched.name
											? 'rgb(245, 101, 12)'
											: 'rgb(45, 45, 45)',
									}}
									placeholder='Введите имя'
								/>
							</InputWrapper>
							<InputWrapper>
								{errors.phone && touched.phone ? (
									<ErrorMessage>
										<CrossIcon>&#10006;</CrossIcon>
										{errors.phone}
									</ErrorMessage>
								) : values.phone && touched.phone ? (
									<CorrectMessage>
										<CheckmarkIcon>&#10003;</CheckmarkIcon>
										Телефон введен верно
									</CorrectMessage>
								) : null}

								<InputMask
									mask='+7 (999) 999-99-99'
									className='valueForm'
									name='phone'
									placeholder='Телефон'
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.phone}
									style={{
										borderColor: errors.phone
											? 'red'
											: values.phone && touched.phone
											? 'rgb(245, 101, 12)'
											: 'rgb(45, 45, 45)',
									}}
								/>
							</InputWrapper>
							<InputWrapper>
								{errors.email && touched.email ? (
									<ErrorMessage>
										<CrossIcon>&#10006;</CrossIcon>
										{errors.email}
									</ErrorMessage>
								) : values.email && touched.email ? (
									<CorrectMessage>
										<CheckmarkIcon>&#10003;</CheckmarkIcon>
										Адрес электронной почты верный
									</CorrectMessage>
								) : null}

								<input
									className='valueForm'
									type='email'
									name='email'
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.email}
									style={{
										borderColor: errors.email
											? 'red'
											: values.email && touched.email
											? 'rgb(245, 101, 12)'
											: 'rgb(45, 45, 45)',
									}}
									placeholder='Введите email'
								/>
							</InputWrapper>
						</InputsWrapper>
						<textarea
							className='textareaValue'
							name='message'
							onChange={handleChange}
							onBlur={handleBlur}
							value={values.message}
							placeholder='Сообщение'
						/>
						<InputWrapper>
							<input
								type='file'
								name='file'
								onChange={e => {
									const file = e.target.files[0]
									setFileLoaded(!!file)
									setFieldValue('file', file)
								}}
								onBlur={handleBlur}
								id='file-input'
								style={{ display: 'none' }}
							/>
							<FileLabel htmlFor='file-input' className='file-label'>
								{fileLoaded ? (
									<>
										<CheckmarkIcon>&#10003;</CheckmarkIcon>
										Файл загружен
									</>
								) : (
									<>
										<FileIcon>&#128206;</FileIcon>
										{values.file ? values.file.name : 'Загрузить файл'}
									</>
								)}
							</FileLabel>
						</InputWrapper>
						<FilterCheckbox>
							<input
								type='checkbox'
								name='consent'
								checked={values.consent}
								onChange={handleChange}
								onBlur={handleBlur}
							/>
							Согласие на обработку персональных данных
						</FilterCheckbox>
						<Button
							type='submit'
							disabled={
								isSubmitting ||
								!values.consent ||
								!values.name ||
								!values.phone ||
								!values.email
							}
						>
							Отправить заявку
						</Button>
					</form>
				)}
			</Formik>
		</div>
	)
}

export default Form
